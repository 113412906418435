import { APICore } from './apiCore';
import { activeConfig } from 'config/clientConfig';

const api = new APICore();
const BASE_URL = `/${activeConfig.api.DOMAIN}/`;

enum MeasurementType {
    Temperature = 'Temperature',
    Weight = 'Weight',
    Height = 'Height',
    Hemoglobin = 'Hemoglobin',
    OxygenSaturation = 'Oxygen saturation',
    Glucose = 'Glucose',
    Cholesterol = 'Cholesterol',
    UricAcid = 'Uric Acid',
    Lactate = 'Lactate',
    Triglycerides = 'Triglycerides',
    Ketone = 'Ketone',
    BloodPressure = 'Blood Pressure',
    HeartBeats = 'Heart Beats'
}

interface MeasurementParams {
    uuid_user: string;
    uuid_edit: string;
    month: string;
    year: string;
}

interface MeasurementPayload extends MeasurementParams {
    type_measurement: MeasurementType;
}

function getMeasurement(params: MeasurementParams, type: MeasurementType) {
    const payload: MeasurementPayload = {
        ...params,
        type_measurement: type,
    };
    return api.create(`${BASE_URL}getMeasurement/`, payload);
}

const getTemps = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Temperature);
const getWeights = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Weight);
const getHeights = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Height);
const getHemoglobins = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Hemoglobin);
const getOxygenSaturations = (params: MeasurementParams) => getMeasurement(params, MeasurementType.OxygenSaturation);
const getGlucose = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Glucose);
const getCholesterol = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Cholesterol);
const getUricAcid = (params: MeasurementParams) => getMeasurement(params, MeasurementType.UricAcid);
const getLactate = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Lactate);
const getTriglycerides = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Triglycerides);
const getKetone = (params: MeasurementParams) => getMeasurement(params, MeasurementType.Ketone);
const getBloodPressure = (params: MeasurementParams) => getMeasurement(params, MeasurementType.BloodPressure);
const getHeartbeats = (params: MeasurementParams) => getMeasurement(params, MeasurementType.HeartBeats);

interface LastMeasurementsParams {
    uuid_user: string;
    month: string;
    year: string;
    uuid_edit: string;
}

// https://api.cuideme.care/Integracao/Monitoramento?imei=00200499009
/* Funcion para obtencion de records para CC care */
interface CC_Care_Records_Params {
    cpf: string;
}

function get_CC_Care_Records(params: CC_Care_Records_Params) {
    const cc_care_endpoint:string = `https://api.cuideme.care/Integracao/Monitoramento?imei=${params.cpf}`;

    return api.get(cc_care_endpoint, null);
}

//https://api.cuideme.care/Integracao/Monitoramento/UUID_doctor
interface CC_Care_Upload_Props {
    Cpf: string,
    FrequenciaCardiaca?: number,
    Sistolica?: number,
    Diastolica?: number, 
    Saturacao?: number, 
    AcucarNoSangue?: number,
}
function set_CC_Care_New_Record(doctor_UUID:string, params:CC_Care_Upload_Props){
    return api.create(`https://api.cuideme.care/Integracao/Monitoramento/${doctor_UUID}`, params);
}

function getLastMeasurements(params: LastMeasurementsParams) {
    return api.create(`${BASE_URL}getLastMeasurements/`, params);
}

interface AddMeasurementParams {
    uuid_user: string;
    value: string;
    uuid_device: string;
    measurement_date: string;
    type_measurement: string;
    uuid_edit: string;
}

function addMeasurement(params: AddMeasurementParams) {
    return api.create(`${BASE_URL}addMeasurement/`, params);
}

interface UpdateParams {
    uuid_user: string;
    measurementId: string;
    statusCurrent: string;
    comment: string;
}

function updateMeasurement(params: UpdateParams) {
    const payload = {
        uuid_user: params.uuid_user,
        measurement_id: parseFloat(params.measurementId),
        status_measurement: params.statusCurrent,
        comment: params.comment,
    };
    return api.create(`${BASE_URL}UpdateMeasurement/`, payload);
}

function updateNotification(params: UpdateParams) {
    const payload = {
        uuid_user: params.uuid_user,
        measurement_id: parseFloat(params.measurementId),
        status_measurement: params.statusCurrent,
        comment: params.comment,
    };
    return api.create(`${BASE_URL}UpdateNotification/`, payload);
}

export {
    MeasurementType,
    getMeasurement,
    addMeasurement,
    getTemps,
    getHeights,
    getWeights,
    getOxygenSaturations,
    getGlucose,
    getBloodPressure,
    getCholesterol,
    getUricAcid,
    getLactate,
    getTriglycerides,
    getKetone,
    getLastMeasurements,
    getHeartbeats,
    getHemoglobins,
    updateMeasurement,
    updateNotification,

    get_CC_Care_Records,
    set_CC_Care_New_Record
};