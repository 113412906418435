import { MedicalSpeciality } from 'pages/RPM/PatientProfile/Comments/types';
import { APICore } from './apiCore';
import { activeConfig } from 'config/clientConfig';

const api = new APICore();

type UpdateUserData = {
    uuid: string,
    uuid_edit: string
    permission: number
    email: string
    user_name: string
    type_user_id: number
    password: string
    uuid_clinic: string
    reference_id: string
    name: string
    last_name: string
    birth_date: string
    phone: string
    emergency_phone: string
    birth_address: string
    address: string
    height: number
    Weight: number
    gender_id: number
    photo: string
    blood_type?: number
    about_user: string
    unit_temperature_id: number
    unit_weight_id: number
    unit_height_id: number
    emergencyName?: string
    emergencyCellphone?: string
    emergencyRelationship?: string
};

export type GeneralHistory = {
    uuid: string,
    uuid_edit: string
    /* Habits */
    smoke: boolean,
    cigarretes: string,
    years_smoking: string,
    alcoholic: boolean,
    drink_frequency_id: number,
    fracture: boolean,
    which_surgery: string,
    when_surgery: string,
    allergic: boolean,
    allergy_medicine: string,
    vaccine_schedule: boolean,
    missing_vaccines: string,
    physical_activity: boolean,
    exercise_type: string,
    exercise_frequency_id: string,
    family_history_mother: string,
    family_history_father: string,
    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,
    health_plan_insurance: boolean,
    whichHealthPlan: string,
    policyNumber: string,
    validityDate: string,
    /* Vaccines */
    vaccinated_hepatitis: boolean,
    immune_to_chickenpox: boolean,
    immune_to_measles: boolean
};

export type InternallHistory = {
    uuid: string,
    uuid_edit: string
    /* Ailments */
    arrhythmia: boolean,            // arrhythmia
    asthma: boolean,                // asthma
    cancer: boolean,                // cancer    
    cirrhosis: boolean,             // cirrhosis
    diabetes: boolean,              // diabetes
    epilepsy: boolean,              // epilepsy
    arterial_hypertension: boolean, // arterial_hypertension
    hypothyroidism: boolean,        // hypothyroidism
    headache: boolean,              // headache
    obesity: boolean,               // obesity
    stroke: boolean,                // stroke    
    thrombosis: boolean,            // thrombosis    
    depression: boolean,            // depression
    anxiety: boolean,               // anxiety
    rheumatoid_arthritis: boolean,  // rheumatoid_arthritis
    lupus: boolean,                 // lupus
    others: boolean,                // others    
    others_pathology: string,       // others_pathology  
    /* Habits */
    smoke: boolean,                 // smoke 
    cigarretes: string,             // cigarretes 
    years_smoking: string,          // years_smoking
    alcoholic: boolean,             // alcoholic
    drink_frequency_id: number,     // drink_frequency_id
    fracture: boolean,              // fracture
    which_surgery: string,          // which_surgery
    when_surgery: string,           // when_surgery
    allergic: boolean,              // allergic
    allergy_medicine: string,       // allergy_medicine
    physical_activity: boolean,     // physical_activity
    exercise_type: string,          // exercise_type
    exercise_frequency_id: string,  // exercise_frequency_id
    family_history_mother: string,  // family_history_mother 
    family_history_father: string,  // family_history_father
    type_of_diet: string,           // type_of_diet 
    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,
};

export type GynecologyHistory = {
    uuid: string,
    uuid_edit: string,

    /* Medical History Variables */
    surgery: boolean,
    surgeryDescription: string,
    healthIssues: boolean,
    healthIssuesDescription: string,

    /* Habits */
    allergic: boolean,
    allergy_medicine: string,

    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,

    /* Sexual History */
    sexuallyActive: boolean,
    inconvenience: boolean,
    bleeding: boolean,
    partner: boolean,
    partnerType: number,
    hadETS: boolean,
    hadETSDescription: string,
}

export type PediatricHistoryInterface = {
    uuid: string,
    uuid_edit: string,

    /* Medical History Variables */
    relevantIssues: boolean,
    hospitalized: boolean,

    /* Habits */
    allergic: boolean,
    allergy_medicine: string,

    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,

    /* Familiar Antecedents */
    hereditary: boolean,
    hereditarySel: string,
    alergyAutoinmune: boolean,

    /* Growth */
    mentalIssues: boolean,
    growthlIssues: boolean,

    /* Vaccines */
    vacBCG: boolean,
    hepatitisA: boolean,
    vaccinated_hepatitis: boolean,
    pentavalent: boolean,
    poliomyelitis: boolean,
    rotavirus: boolean,
    pneumococcus: boolean,
    meningococcus: boolean,
    immune_to_measles: boolean,
    immune_to_chickenpox: boolean,

    /* Feeding and Nutrition */
    diet: number,
    feedingIssues: boolean,
    nutritionIssues: boolean
}

export type DermatologyHistoryInterface = {
    uuid: string,
    uuid_edit: string,

    /* Medical History Variables */
    relevantIssues: boolean,
    hospitalized: boolean,

    /* Habits */
    alergySkinProducts: boolean,
    alergySkinProductsDescription: string,
    allergic: boolean,
    allergy_medicine: string,

    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,

    /* Familiar Antecedents */
    skinDiseaseParent: boolean,

    /* Dermatology Background */
    skinIssue: boolean,
    skinIssueDescription: string,
    skinDisease: boolean,
    skinSurgery: boolean,
    skinCareProduct: boolean,
    skinCareProductDescription: string
}

export type OphtalmologyHistoryInterface = {
    uuid: string,
    uuid_edit: string,

    /* Medical History Variables */
    relevantIssues: boolean,
    hospitalized: boolean,

    /* Habits */
    allergic: boolean,
    allergy_medicine: string,

    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,

    /* Family History */
    parentSightDisease: boolean,

    /* Ophtalomology background*/
    sightIssue: boolean,
    sightIssueDescription: string,
    sightDisease: boolean,
    sightSurgery: boolean,
    glasses: boolean,
}

export type CardiologyHistoryInterface = {
    uuid: string,
    uuid_edit: string,

    /* Medical History Variables */
    relevantIssues: boolean,
    hospitalized: boolean,

    /* Habits */
    allergic: boolean,
    allergy_medicine: string,

    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,

    /* Parantal Background vriables */
    parentCardiologicProblem: boolean,

    /* Risk Factors Variables */
    smoker: boolean,
    overweight: boolean,
    hipertense: boolean,
    colesterol: boolean,
    diabetes: boolean,
    physicalActivity: boolean,
    emotionalAnxiety: boolean,
    heartSurgery: boolean,
}

export type SurgeryHistoryInterface = {
    uuid: string,
    uuid_edit: string,

    /* Familiar Antecedents */
    parentSurgery: boolean,

    /* Medical History Variables */
    relevantIssues: boolean,
    hospitalized: boolean,

    /* Habits */
    allergic: boolean,
    allergy_medicine: string,

    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,

    /* Surgeries */
    hernia: boolean,
    apendicitis: boolean,
    gallstones: boolean,
    hipFracture: boolean,
    colorectalCancer: boolean,
    intestinalObstruction: boolean,
    pepticUlcer: boolean,
    traumaticLession: boolean,
    burnGraves: boolean,
    anomalies: boolean,
    surgeryIssues?: boolean,
    surgeryIssuesDescription?: string
}

export type OtorhinolaringologyHistoryInterface = {
    uuid: string,
    uuid_edit: string,

    /* Medical History Variables */
    relevantIssues: boolean,
    hospitalized: boolean,

    /* Habits */
    allergic: boolean,
    allergy_medicine: string,

    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,

    /* Family History */
    otorhinolarygologyIssueParent: boolean,

    /* Otorhinolaringology background*/
    surgery: boolean,
    surgeryDescription: string,
}

export type PschiatryHistoryInterface = {
    uuid: string,
    uuid_edit: string,

    /* Medical History Variables */
    relevantIssues: boolean,
    hospitalized: boolean,

    /* Habits */
    allergic: boolean,
    allergy_medicine: string,

    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,

    /* Family History */
    mentalIssueParent: boolean,

    /* Otorhinolaringology background*/
    psychiatricTreatment: boolean,
}

export type GastroenterologyHistoryInterface = {
    uuid: string,
    uuid_edit: string,

    /* Medical History Variables */
    relevantIssues: boolean,
    hospitalized: boolean,

    /* Habits */
    allergic: boolean,
    allergy_medicine: string,

    /* Medicines */
    medicine_use: boolean,
    which_medicine_use: string,

    /* Family History */
    gastricIssues: boolean,

    /* Otorhinolaringology background*/
    abdominalSurgery: boolean,
}

type PathologyUserData = {
    uuid: string,
    uuid_edit: string
    DiagnosticDate: string
    DischargeDate: string
    Comment: string
    pathology_type: number
};
type UpdatePathologyUserData = {
    uuid: string,
    uuid_edit: string
    diagnosticDate: string
    dischargeDate: string
    comment: string
    pathologyUserId: number
    pathology_type: number
    statusPathology: string

};
type MedicineUserData = {
    uuid: string,
    uuid_edit: string
    StartTreatmentDate: string
    EndTreatmentDate: string

    Dose: string
    TreatmentDays: string
    TimesDay: string

    Medicine_type: number
    ActivePrinciple: number
    Concentration: number
    PharmaceuticalForm: number
    Comment: string

};
type UpdateMedicineUserData = {
    uuid: string,
    uuid_edit: string
    StartTreatmentDate: string
    // EndTreatmentDate:string 

    Dose: string
    TreatmentDays: string
    TimesDay: string

    MedicineUserId: number
    Medicine_type: number
    finished_doctor: string

};

function getUserData(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserInfo/`;
    const payload = {
        uuid: params.uuid,
    }
    return api.create(`${baseUrl}`, payload);
}

function getPatientData(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getPatientInfo/`;
    const payload = {
        uuid: params.uuid,
    }
    return api.create(`${baseUrl}`, payload);
}

function getUserMedicalRecords(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserMedicalHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserInternalHistory(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserInternalHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserGynecologyHistory(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserGinecologyHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserPediatricHistory(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserPediatricsHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserOphtalmologyHistory(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserOphtalmologyHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserDermatologyHistory(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserDermatologyHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserCardiologyHistory(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserCardiologyHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserSurgeryHistory(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserSurgeryHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserOtorhinolaryngologyHistory(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserOtorhinolaryngologyHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserPschiatryHistory(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserPsychiatryHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}
function getUserPreferences(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserPreferences/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserGastroenterologyHistory(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserGastroenterologyHistory/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function updateGeneralHistory(generalHistory: GeneralHistory) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateGeneralHistory/`;
    const payload = generalHistory;

    return api.create(`${baseUrl}`, payload);
}

function updateInternalHistory(internalHistory: InternallHistory) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateInternalHistory/`;
    const payload = internalHistory;

    return api.create(`${baseUrl}`, payload);
}

function updateGynecologyHistory(gynecologyHistory: GynecologyHistory) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateGinecologyHistory/`;
    const payload = gynecologyHistory;

    return api.create(`${baseUrl}`, payload);
}

function updatePediatricHistory(pediatricHistory: PediatricHistoryInterface) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updatePediatricsHistory/`;
    const payload = pediatricHistory;

    return api.create(`${baseUrl}`, payload);
}

function updateOphtalmologyHistory(ophtalmologyHistory: OphtalmologyHistoryInterface) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateOphtalmologyHistory/`;
    const payload = ophtalmologyHistory;

    return api.create(`${baseUrl}`, payload);
}

function updateDermatologyHistory(dermatologyHistory: DermatologyHistoryInterface) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateDermatologyHistory/`;
    const payload = dermatologyHistory;

    return api.create(`${baseUrl}`, payload);
}

function updateCardiologyHistory(cardiologyHistory: CardiologyHistoryInterface) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateCardiologyHistory/`;
    const payload = cardiologyHistory;

    return api.create(`${baseUrl}`, payload);
}

function updateSurgeryHistory(surgeryHistory: SurgeryHistoryInterface) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateSurgeryHistory/`;
    const payload = surgeryHistory;

    return api.create(`${baseUrl}`, payload);
}

function updateUserOtorhinolaryngologyHistory(otorhinolaryngologyHistory: OtorhinolaringologyHistoryInterface) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateOtorhinolaryngologyHistory/`;
    const payload = otorhinolaryngologyHistory;

    return api.create(`${baseUrl}`, payload);
}

function updatePschiatryHistory(PschiatryHistory: PschiatryHistoryInterface) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updatePsychiatryHistory/`;
    const payload = PschiatryHistory;

    return api.create(`${baseUrl}`, payload);
}

function updateGastroenterologyHistory(GastroenterologyHistory: GastroenterologyHistoryInterface) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateGastroenterologyHistory/`;
    const payload = GastroenterologyHistory;

    return api.create(`${baseUrl}`, payload);
}

function getUserHistoryComments(params: { uuid: string, speciality: MedicalSpeciality }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserHistoryComments/`;
    const payload = {
        uuid: params.uuid,
        speciality: params.speciality
    }

    return api.create(`${baseUrl}`, payload);
}

function createUserHistoryComment(params: { uuid: string, uuid_edit: string, speciality: MedicalSpeciality, comment: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/createUserHistoryComment/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit,
        speciality: params.speciality,
        comment: params.comment
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserClinic(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetUserClinic/`;
    const payload = {
        uuid: params.uuid,
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}

function updateUser(user: UpdateUserData) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateUser/`;
    const payload = user

    return api.create(`${baseUrl}`, payload);
}

function getCampaigns(params: { uuid?: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getCampaigns/`;
    const payload = {
        uuid: params.uuid ? params.uuid : '',
        campaignStatus: ''
    }

    return api.create(`${baseUrl}`, payload);
}

function getUsers(params: { TypeUser: number, uuid_edit: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUsers/`;
    const payload = {
        type_user_id: params.TypeUser,
        uuid_edit: params.uuid_edit
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserInfo(params: { uuid_user: string, user_name: string, TypeUser: number }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetUserInfo/`;
    const payload = {
        email: "",
        user_name: params.user_name,
        password: "",
        reference_id: "",
        name: "",
        last_name: "",
        birth_date: "",
        phone: "",
        gender_id: 0,
        address: "",
        type_user_id: params.TypeUser
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserParameters(params: { uuid: string, uuid_edit: string, parameterType: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserParameters/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit,
        parameterType: params.parameterType,

    }

    return api.create(`${baseUrl}`, payload);
}

function getParameterState(params: { uuid: string, measurementType: number }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getParameterState/`;
    const payload = {
        uuid: params.uuid,
        measurementType: params.measurementType,
    }

    return api.create(`${baseUrl}`, payload);
}

function setParameterState(params: { uuid: string, measurementType: number }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/setParameterState/`;
    const payload = {
        uuid: params.uuid,
        measurementType: params.measurementType,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserNotifications(params: { uuid: string, measurement_type: string, notification_level: string, notification_status: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserNotifications/`;
    const payload = {
        uuid: params.uuid,
        measurement_type: params.measurement_type,
        notification_level: params.notification_level,
        notification_status: params.notification_status
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserNotificationsConsult(params: { uuid: string, measurement_type: string, notification_level: string, notification_status: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserNotificationsConsult/`;
    const payload = {
        uuid: params.uuid,
        measurement_type: params.measurement_type,
        notification_level: params.notification_level,
        notification_status: params.notification_status
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserEmergencyNotifications(params: { uuid: string, notification_level: string, notification_status: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserEmergencyNotifications/`;
    const payload = {
        uuid: params.uuid,
        notification_level: params.notification_level,
        notification_status: params.notification_status
    }

    return api.create(`${baseUrl}`, payload);
}

function updateUserParameters(params: {
    uuid: string,
    criticalHigh: string,
    normalMax: string,
    normalMin: string,
    criticalLow: string,
    comment: string,
    type: string
}) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateUserParameters/`;
    const payload = {
        uuid: params.uuid,
        criticalHigh: params.criticalHigh,
        normalMax: params.normalMax,
        normalMin: params.normalMin,
        criticalLow: params.criticalLow,
        comment: params.comment,
        type: params.type
    }

    return api.create(`${baseUrl}`, payload);
}

function updateUserParameters2(params: {
    uuid: string,
    criticalHigh1: string,
    normalMax1: string,
    normalMin1: string,
    criticalLow1: string,
    criticalHigh2: string,
    normalMax2: string,
    normalMin2: string,
    criticalLow2: string,
    comment: string,
    type: string
}) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateUserParameters2/`;
    const payload = {
        uuid: params.uuid,
        criticalHigh1: params.criticalHigh1,
        normalMax1: params.normalMax1,
        normalMin1: params.normalMin1,
        criticalLow1: params.criticalLow1,

        criticalHigh2: params.criticalHigh2,
        normalMax2: params.normalMax2,
        normalMin2: params.normalMin2,
        criticalLow2: params.criticalLow2,

        comment: params.comment,
        type: params.type
    }
    return api.create(`${baseUrl}`, payload);
}

function updateUserParameters4(params: {
    uuid: string,
    criticalHigh1: string,
    normalMax1: string,
    normalMin1: string,
    criticalLow1: string,

    criticalHigh2: string,
    normalMax2: string,
    normalMin2: string,
    criticalLow2: string,

    criticalHigh3?: string,
    normalMax3?: string,
    normalMin3?: string,
    criticalLow3?: string,

    criticalHigh4: string,
    normalMax4: string,
    normalMin4: string,
    criticalLow4: string,

    comment: string,
    type: string
}) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateUserParameters4/`;
    const payload = {
        uuid: params.uuid,
        criticalHigh1: params.criticalHigh1,
        normalMax1: params.normalMax1,
        normalMin1: params.normalMin1,
        criticalLow1: params.criticalLow1,

        criticalHigh2: params.criticalHigh2,
        normalMax2: params.normalMax2,
        normalMin2: params.normalMin2,
        criticalLow2: params.criticalLow2,

        /*         criticalHigh3: params.criticalHigh3,
                normalMax3: params.normalMax3,
                normalMin3: params.normalMin3,
                criticalLow3: params.criticalLow3, */

        criticalHigh4: params.criticalHigh4,
        normalMax4: params.normalMax4,
        normalMin4: params.normalMin4,
        criticalLow4: params.criticalLow4,

        comment: params.comment,
        type: params.type
    }
    return api.create(`${baseUrl}`, payload);
}

function CreateUserForAdmin(user: UpdateUserData) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/CreateUserForAdmin/`;
    const payload = user

    // console.log('user')
    // console.log(user)
    return api.create(`${baseUrl}`, payload);
}


function getParameters(params: { name: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getParameters/`;
    const payload = {
        name: params.name
    }
    // console.log('Clients');
    return api.create(`${baseUrl}`, payload);
}
/*******************************************************************************/
function GetActivePrinciple(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetActivePrinciple/`;
    const payload = {
        uuid: params.uuid,
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}
function GetConcentration(params: { uuid: string, active_principle_id: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetConcentration/`;
    const payload = {
        uuid: params.uuid,
        activePrinciple: params.active_principle_id,
    }
    //   console.log( payload);
    return api.create(`${baseUrl}`, payload);
}
function GetPharmaceuticalForm(params: { uuid: string, concentration_id: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetPharmaceuticalForm/`;
    const payload = {
        uuid: params.uuid,
        concentration: params.concentration_id,
    }
    //  console.log( payload);
    return api.create(`${baseUrl}`, payload);
}

/*******************************************************************************/
function getUserStatisticsDoctor(params: { uuid_user: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserStatisticsDoctor/`;
    const payload = {
        uuid: params.uuid_user,

    }

    return api.create(`${baseUrl}`, payload);
}
/************************************************************************************/

function getUuidNotificationSingle(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUuidNotificationSingle/`;
    const payload = {
        uuid: params.uuid,
        // PathologyUserId: params.PathologyUserId,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserPathologiesSingle(params: { uuid: string, PathologyUserId: number }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetUserPathologiesSingle/`;
    const payload = {
        uuid: params.uuid,
        PathologyUserId: params.PathologyUserId,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserPathologies(params: { uuid: string, uuid_edit: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetUserPathologies/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}

function GetUserNotification(params: { uuid: string, uuid_edit: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetUserNotification/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit
    }
    // console.log( payload);
    return api.create(`${baseUrl}`, payload);
}

function createSystemReminder(params: {
    uuid: string
    , uuid_edit: string
    , notification_level: string
    , comment: string
    , commentDoctor: string
    , val_time: string,
    before_time: string,
    UuidNotification: string,
}) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/createSystemReminder/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit,
        notification_level: params.notification_level,
        comment: params.comment,
        commentDoctor: params.commentDoctor,
        val_time: params.val_time,
        before_time: params.before_time,
        UuidNotification: params.UuidNotification,
    }

    return api.create(`${baseUrl}`, payload);
}

function UpdateSystemReminder(params: {
    uuid: string
    , uuid_edit: string
    , notification_level: string
    , comment: string
    , val_time: string
    , before_time: string
    , UuidNotification: string
}) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/UpdateSystemReminder/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit,
        notification_level: params.notification_level,
        comment: params.comment,
        val_time: params.val_time,
        before_time: params.before_time,
        UuidNotification: params.UuidNotification,
    }
    return api.create(`${baseUrl}`, payload);
}

function getPathologies(params: { uuid: string, category: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetPathologies/`;
    const payload = {
        uuid: params.uuid,
        category: params.category,
    }

    return api.create(`${baseUrl}`, payload);
}

function getCategoriesPathology(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetCategoriesPathology/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function newPathology(user: PathologyUserData) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/PathologyUser/`;
    const payload = user

    return api.create(`${baseUrl}`, payload);
}

function UpdatePathologyUser(user: UpdatePathologyUserData) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/UpdatePathologyUser/`;
    const payload = user

    return api.create(`${baseUrl}`, payload);
}

function DeletePathologyUser(user: UpdatePathologyUserData) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/DeletePathologyUser/`;
    const payload = user

    return api.create(`${baseUrl}`, payload);
}

function DeleteUUidNotificationAgentUser(params: { uuidNotification: string, uuidUser: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/DeleteUUidNotificationAgentUser/`;
    const payload = {
        uuid: params.uuidNotification,
        uuid_edit: params.uuidUser
    }

    return api.create(`${baseUrl}`, payload);
}

function DeleteMedicineUser(user: UpdateMedicineUserData) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/DeleteTreatmentUser/`;
    const payload = user

    return api.create(`${baseUrl}`, payload);
}

function getUserMedicines(params: { uuid: string, uuid_edit: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetUserMedicines/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserMedicinesSingle(params: { uuid: string, MedicineUserId: number }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetUserMedicinesSingle/`;
    const payload = {
        uuid: params.uuid,
        MedicineUserId: params.MedicineUserId,
    }
    console.log(payload);
    return api.create(`${baseUrl}`, payload);
}

function getMedicines(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/GetMedicines/`;
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function MedicineUser(user: MedicineUserData) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/MedicineUser/`;
    const payload = user;

    return api.create(`${baseUrl}`, payload);
}

function UpdateTreatmentUser(user: UpdateMedicineUserData) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/UpdateTreatmentUser/`;
    const payload = user;

    return api.create(`${baseUrl}`, payload);
}
function getUserLastMeasurementsReport(params: {
    startDate: string, endDate: string, userType: number, gender: number, ageLess: string, ageOlder: string,
    name: string, lastname: string, doctorName: string, doctorLastname: string, healthCampaign: number
}) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getReportUser/`;
    const payload = {
        date_start: params.startDate,
        date_end: params.endDate,
        type_user_id: params.userType,
        gender_id: params.gender,
        age_minor: params.ageLess,
        age_major: params.ageOlder,
        name: params.name,
        last_name: params.lastname,
        name_doctor: params.doctorName,
        last_name_doctor: params.doctorLastname,
        health_campaign_id: params.healthCampaign,
    }

    return api.create(`${baseUrl}`, payload);
}

function UpdateNotificationGeneric(params: { uuid_user: string, nogId: string, statusCurrent: string, comment: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/UpdateNotificationGeneric/`;
    const payload = {
        uuid_edit: params.uuid_user,
        nog_id: params.nogId,
        status_measurement: params.statusCurrent,
        comment: params.comment,

    }

    return api.create(`${baseUrl}`, payload);
}

function UpdateStatusEmergencieNotificationGeneric(params: { uuid_user: string, nogId: string, statusCurrent: string, comment: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/UpdateStatusEmergencieNotificationGeneric/`;
    const payload = {
        uuid_edit: params.uuid_user,
        nog_id: params.nogId,
        status_measurement: params.statusCurrent,
        comment: params.comment,
    }

    return api.create(`${baseUrl}`, payload);
}

function UpdateStatusNotification(params: { uuid_user: string, status: string, nusId: string, read_all: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/UpdateStatusNotification/`;
    const payload = {
        uuid_edit: params.uuid_user,
        status: params.status,
        nusId: params.nusId,
        read_all: params.read_all

    }
    //console.log(payload);
    return api.create(`${baseUrl}`, payload);
}

export {
    getUserData, getPatientData, updateUser, getUserClinic, 
    getUsers, getUserInfo, getUserPathologies, getPathologies, getCategoriesPathology
    , newPathology as PathologyUser, UpdatePathologyUser, DeletePathologyUser
    , getMedicines, getUserMedicines, MedicineUser, DeleteMedicineUser, UpdateTreatmentUser
    , GetActivePrinciple, GetConcentration, GetPharmaceuticalForm, getUserPathologiesSingle
    , getUserMedicinesSingle, getUserStatisticsDoctor, getParameters, CreateUserForAdmin, getCampaigns
    , getUserMedicalRecords, updateGeneralHistory, getUserHistoryComments, createUserHistoryComment, updateInternalHistory, getUserInternalHistory
    , getUserGynecologyHistory, updateGynecologyHistory
    , getUserPediatricHistory, updatePediatricHistory
    , getUserOphtalmologyHistory, updateOphtalmologyHistory
    , getUserDermatologyHistory, updateDermatologyHistory
    , getUserCardiologyHistory, updateCardiologyHistory
    , getUserSurgeryHistory, updateSurgeryHistory
    , getUserOtorhinolaryngologyHistory, updateUserOtorhinolaryngologyHistory
    , getUserPschiatryHistory, updatePschiatryHistory
    , getUserGastroenterologyHistory, updateGastroenterologyHistory
    , getUserLastMeasurementsReport, getUserParameters, getUserNotifications, updateUserParameters, updateUserParameters2, updateUserParameters4, getParameterState, setParameterState
    , getUserEmergencyNotifications
    , getUserNotificationsConsult
    , UpdateNotificationGeneric
    , UpdateStatusNotification
    , UpdateStatusEmergencieNotificationGeneric, getUserPreferences
    , GetUserNotification, createSystemReminder, DeleteUUidNotificationAgentUser, UpdateSystemReminder
    , getUuidNotificationSingle
};
