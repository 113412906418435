import { combineReducers } from 'redux';
import Auth from './auth/reducers';
import Layout from './layout/reducers';
import Campaign from './campaign/reducers';
import History from './medicalHistory/reducers';

export default combineReducers({
    Auth,
    Layout,
    Campaign,
    History
});
