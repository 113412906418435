import { MedicalHistoryActionType } from "./actions";
import { MedicalHistoryActionTypes, HistoryStateType } from "./constants";

const INIT_STATE: HistoryStateType = {
    status: false
};

const History = (state = INIT_STATE, action: MedicalHistoryActionType) => {
    switch (action.type) {
        case MedicalHistoryActionTypes.CHANGE_HISTORY_STATUS:
            return {
                ...state,
                status: action.payload.status,
            };

        default:
            return { ...state };
    }
}

export default History;